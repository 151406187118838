.newPasswordContainer{
          max-width: 500px;
          margin-left: auto;
          margin-right: auto;
          margin: 50px auto;
}
.passRe{
          padding-bottom: 20px;
          justify-content: center;
          align-items: center;
}

.input-wrap{
          padding-bottom: 10px;
}

.input_type{
          margin-left: 20px;
          width: 250px;
          height: 30px;
          border-radius: 7px;
          padding: 0 10px;
}
.resetbtn{
          width: 200px;
          margin: 20px auto;
}


/* MOBILE SCREEN */
.newPasswordContainer{
          margin: 20px auto;
          padding: 0 20px;
}

