.container{
          max-width: 320px;
          margin: auto;
          padding-top: 100px;
          padding-bottom: 100px;
         
         }

         .checkout-form{
                    padding-top: 10px;
         }
         
         .input{
          display: flex;
          flex-direction: column;
         }
         
         .label{
          font-weight: bold;
         }
         
         .inputElement{
          height: 25px;
          border-radius: 8px;
          outline: none;
          margin-top: 8px;
          padding-left: 7px;
          max-width: 320px;
         }
         
         .paysBtn{
          margin-top: 15px;
          width: 150px;
          height: 30px;
          background: green;
          outline: transparent;
          font-weight: 600;
          color: #fff;
          font-size: 18px;
          margin-left: auto;
          margin-right: auto;
          border-radius: 10px;
         }
         
         @media screen and (max-width: 768px) {
          .inputElement{
           width: 100%;
          }
         }
         