.adminNavbar{
 height: 50px;
 border-bottom: 0.5px solid rgb(231, 228, 228);
 display: flex;
 align-items: center;
 font-size: 14px;
 color: #555;

 .wrapper{
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;

  .search{
   display: flex;
   align-items: center;
   border: 0.5px solid lightgray;
   padding: 3px;

   input{
    border: none;
    outline: none;
    background-color: transparent;

    &::placeholder{
     font-style: 12px;
    }
   }
  }


  .items{
   display: flex;
   align-items: center;
   
   .item{
    display: flex;
    align-items: center;
    margin-right: 20px;
    position: relative;

    .icon{
     font-style: 20px;
    }

    .avatar{
     width: 30px;
     height: 30px;
     border-radius: 50%

    }

    .counter{
     width: 15px;
     height: 15px;
     background-color: red;
     border-radius: 50%;
     color: #fff;
     display: flex;
     align-items: center;
     justify-content: center;
     font-style: 10px;
     font-weight: bold;
     position: absolute;
     top: -5px;
     right: -5px;
    }
   }

  }
 }
}