.signupFormContainer{
 max-width: 600px;
 margin-left: auto;
 margin-right: auto;
 box-shadow: 2px solid darkkhaki;
 padding-bottom: 30px;
 margin-top: 50px;
}
.signupHead{
  text-transform: uppercase;
}
.p-head{
 font-weight: 700px;
 font-size: 22px;
}

.firstdiv, .userMail,.passDiv {
 display: flex;
 justify-content: space-between;
 /* gap: 100px; */
}

.input_label {
 display: block;
 font-weight: 500;
 margin-bottom: 5px;
 font-size: 12px;
}

.input-wrap  {
 margin: 10px 0;
 /* width: 100%; */
}

.input_type {
 display: inline-block;
 /* width: 100%; */
 border-radius: 8px;
 padding: 10px;
 margin: 0 auto;
 font-size: 12px;
 border: 1px solid rgb(51, 51, 51);
}
.errorMsg {
 color: red;
 font-size: 12px;
 font-weight: 600;
 /* display: none; */
}
.tc{
 color: blue;
 font-size: 14px;
}
.checkbox{
 margin-left: 10px;
 /* padding-left: 20px; */
 margin-right: 10px;
}
.already{
 color: green;
 font-weight: bold;
 padding-top: 30px;
 padding-left: 10px;
}
@media screen and (max-width: 768px) {

    .signupHead{
    padding-left: 10px;
  padding-right: 10px;
  font-size: 24px;
    }

 .p-head{
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
 }
 
 .firstdiv, .userMail,.passDiv, .referralcodess {
  display: block;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  /* justify-content: space-between; */
 }
}