.pre{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100vh;
                    margin: auto 0;
                   }

.preloader{
                    width: 80px;
                    height: 80px;
}