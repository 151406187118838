.contactContainer{
                    /* max-width: 450px;
                    margin-left: auto;
                    margin-right: auto; */
                    padding-top: 60px;
                    padding-bottom: 60px;
                   }
                   
                   .contactHead{
                    background-color: green;
                    text-align: center;
                    width: 230px;
                    margin-left: auto;
                    margin-right: auto;
                   }
                   
                   .contactDiv{
                    display: flex;
                    max-width: 1000px;
                    justify-content: space-between;
                    padding-top: 50px;
                    margin-left: auto;
                    margin-right: auto;
                    padding-left: 10px;
                    padding-right: 10px;
                   }
                   .contactDiv1{
                    width: 450px;
                    /* height: 100px; */
                   }
                   .locationPara{
                    padding-top: 100px;
                    padding-bottom: 40px;
                    /* align-items: center; */
                   }
                   .emailPara{
                    padding-bottom: 30px;
                    /* align-items: center;
                    align-content: center; */
                   
                   }
                   .phonePara{
                    padding-bottom: 30px;
                    /* align-items: center; */
                    
                   }
                   .headF{
                    font-size: 48px;
                    padding-top: 10px;
                    padding-bottom: 25px;
                   }
                   .contactDiv2{
                    width: 450px;
                    background-color: green;
                    padding-bottom: 80px;
                    /* height: 100px; */
                   
                   }
                   .formCont{
                    padding-left: 15px;
                    padding-right: 15px;
                   }
                   .contactBlock{
                    display: flex;
                    flex-direction: column;
                    padding-top: 20px;
                   }
                   .labell{
                    padding-bottom: 10px;
                    color: #fff;
                   }
                   .contactInput{
                    height: 40px;
                    border-radius: 10px;
                    border: 1px solid black;
                    background-color: transparent;
                    padding: 5px;
                    color: #fff;
                   }
                   .contactBtnDiv{
                    padding-top: 50px;
                    width: 180px;
                    margin-left: auto;
                    margin-right: auto;
                   }
                   .faIcons{
                    width: 60px;
                    height: 60px;
                    padding-right: 20px;
                   }
                   .faIconss{
                    width: 20px;
                    height: 20px;
                    padding-right: 10px;
                   }
          
          
                   /* Mobile responsiveness or Screen */
                   
                   @media screen and (max-width:768px) {
                    .contactContainer{
                     /* padding-top: 20px; */
                     /* padding-bottom: 20px; */
                    }
                    .contactDiv{
                     flex-direction: column;
                     padding-top: 20px;
                    }
                    .contactDiv1{
                     width: 100%;
                    }
                    .locationPara{
                     padding-top: 20px;
                     padding-bottom: 20px;
                    }
                    .emailPara{
                     padding-bottom: 20px;
                    }
                    .phonePara{
                     padding-bottom: 20px;
                    }
                    .contactDiv2{
                     width: 100%;
                     margin-top: 50px;
                    }
                   }