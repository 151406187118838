.sidebar {
 // background-color: lightcoral;
 flex: 1;
 border-right: 0.5px solid rgb(230, 237, 227);
 min-height: 100vh;
 background-color: white;

 .top{
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.5px solid rgb(230, 237, 227);
  padding-bottom: 15px;
 }

 .center{
  padding-left: 10px;
  padding-top: 10px ;

  .title{
   font-size: 10px;
   font-weight: bold;
   color: gray;
   margin-top: 15px;
   margin-bottom: 5px;
  }

  li {
   display: flex;
   align-items: center;
   padding: 5px;
   font-size: 16px;
   cursor: pointer;

   &:hover {
    background-color: #ece8ff;
   }
   .icon{
    font-size: 18px;
    color: green;
   }
   span{
    font-size: 13px;
    font-weight: 600;
    color: #888;
    margin-left: 10px;
   }
  }
 }
 .bottom {
  display: flex;
  align-items: center;
  margin: 10px;

  .colorOption{
   width: 20px;
   height: 20px;
   border-radius: 5px;
   border: 1px solid #7451f8;
   cursor: pointer;
   margin: 5px;

   &:nth-child(1){
    background-color: whitesmoke;
   }
   &:nth-child(2){
    background-color: #333;
   }
  }
 }
}