.widget{
 // margin-right: 20px;
 display: flex;
 flex: 1;
 padding: 10px;
 justify-content: space-between;
 box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
 border-radius: 10px;
 height: 100px;


 .left, 
 .right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title{
   font-weight: bold;
   font-style: 14px;
   color:  rgb(160, 160, 160);
  }

  .counter{
   font-size: 28px;
   font-weight: 300px;
  }

  .link{
   width: max-content;
   font-size: 12px;
   border-bottom: 1px solid gray;
   cursor: pointer;

   &:hover{
    color: green;
   }
  }

  .percentage{
   display: flex;
   align-items: center;
   font-size: 14px;

   & .positive{
    color: green;
   }
   & .negative{
    color: red;
   }
  }

  .icon{
   font-size: 18px;
   padding: 5px;
   background-color: rgba(128, 0, 128, 0.341);
   border-radius: 5px;
   align-self: flex-end;
  }
 }
}