.formContainer{
          padding: 50px 10px 53px 10px;
          max-width: 450px;
          margin-left: auto;
          margin-right: auto;
         }
         .formwb{
          font-size: 32px;
          padding-bottom: 20px;
          text-transform: uppercase;
         }
         .form{
          font-size: 32px;
          padding-bottom: 20px;
         }
         .buttonFlex{
          max-width: 33.5%;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 15px;
          padding-top: 20px;
         }
         .flexdown{
          display: flex;
          /* justify-content: space-evenly; */
          gap: 20px;
         
         }
         
         .login-email-input, .login-password-input{
          background: #FFFFFF;
         border: 0.5px solid rgb(51, 51, 51);
         border-radius: 8px;
         display: block;
         height: 30px;
         width: 425px;
         padding: 12px;
         font-family: inherit;
         margin-bottom: 25px;
         outline: none;
         margin-top: 10px;
         }
         a{
          text-decoration: none;
          font-size: 1.3rem;
          color: black;
          cursor: pointer;
         }
         .turnoff{
          display: none;
         }
         .login-forgot-password{
          color: green;
          font-weight: bold;
         }
         .login-forgot-password:active{
          color: blueviolet;
         }
         /* .login-forgot-password:last-child{
          /* padding-top: 10px; */
         /* } */
         @media screen and (max-width: 768px) {
          .login-email-input, .login-password-input{
           max-width: 90%;
          }
          .flexdown{
           width: 90%;
           margin-left: auto;
           margin-right: auto;
          
          }
         }
         
         @media screen and (max-width:473px) {
                   .buttonFlex{
                             max-width: 65%;
                             margin-left: auto;
                             margin-right: auto;
                             padding-top: 0px;
                   }
                   
         }