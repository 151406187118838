*{
                    margin: 0;
                    padding: 0;
                   }
                   
                   .section1ContentHead{
                    font-weight: 600;
                    font-size: 3rem;
                    line-height: 2.5rem;
                    text-align: center;
                    padding-top: 6rem;
                    color: #ffffff;
                    margin: 13rem 0 9rem;
                    text-transform: uppercase;
                   } 
                   .section1ContentPara{
                    font-size: 32px;
                    color: white;
                    font-weight: bolder;
                   }
                   
                   
                   .carousel-root {
                    width: 100% !important;
                    margin: auto !important;
                    /* margin-top: 3% !important; */
                   }
                   
                   .carouseldiv11{
                    background-image: url('../../../../asset/images/slides1.jpeg');   
                   }
                   
                   .carouseldiv41{
                    background-image: url('../../../../asset/images/slides4.jpeg');   
                   }
                   .carouseldiv61{
                    background-image: url('../../../../asset/images/slides6.jpeg');   
                   }
                   
                   .carousel-status{
                    display: inline;
                   }
                   
                   .carouseldiv11, .carouseldiv21, .carouseldiv31,.carouseldiv41,.carouseldiv51,.carouseldiv61{
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 100vh;
                    padding: 0.1rem;
                    /* align-items: center; */
                   }
                   
                   .section2{
                       background-color: rgb(51, 51, 51);
                       padding-bottom: 60px;
                       color: #ffffff;
                   
                   }
                   
                   .sectionB{
                       padding-top: 20px;
                    display: flex;
                    justify-content: space-around;
                    color: white;
                   }
                   .sectionBdiv1{
                    max-width: 450px;
                   }
                   .sectionBhead{
                    font-size: 32px;
                    /* padding-top: 10px; */
                    /* padding-bottom: 10px; */
                   }
                   .sectionBListing{
                    padding-top: 5px;
                    font-size: 20px;
                   }
                   .sectionBListing:first-child{
                    padding-top: -10px;
                   }
                   .sectionBdiv2{
                    max-width: 450px;
                    margin-left: auto;
                    margin-right: auto;
                   }
                   /* .sectionBB{
                    margin-top: 20px;
                   } */
                   .sectionBh2{
                    padding-top: 10px;
                    padding-bottom: 10px;
                    font-size: 32px;
                   }
                   .sectionBP{
                    font-weight: bolder;
                    font-size: 20px;
                    padding-top: 30px;
                    padding-bottom: 30px;
                   max-width: 1000px;
                   margin-left: auto;
                   margin-right: auto;
                   }
                   .sectionBPara{
                    padding-top: 40px;
                    font-size: 18px;
                    line-height: 30px;
                    max-width: 1000px;
                    margin-left: auto;
                    margin-right: auto;
                   }
                   .sectionBLink{
                    color: green;
                    font-weight: bolder;
                    /* text-decoration: underline; */
                    background-color: yellow;
                    width: 200px ;
                    height: 70px;
                   border-radius: 10px;
                   padding: 0 5px;
                   }
                   .sectionBLink:hover{
                   transition: 2s;
                   font-size: 30px;
                   }
                   
                   .getStart{
                       width: 150px;
                       margin-left: auto;
                       margin-right: auto;
                       margin-top: 80px;
                   }
                   
                   /* Mobile screen */
                   @media screen and (max-width: 768px) {
                   
                    .section1ContentHead{
                     font-size: 36px;
                    }
                    .section1ContentPara{
                     font-size: 24px;
                     font-weight: bolder;
                    }
                    .sectionB{
                     flex-direction: column;
                     padding-left: 10px;
                     padding-right: 10px;
                     padding-top: 10px;
                    }
                   
                    .sectionBdiv2{
                     padding-top: 20px;
                     padding-left: 10px;
                     padding-right: 10px;
                    }
                    .sectionBh2{
                     background-color: green;
                     max-width: 250px;
                    }
                    .sectionBP{
                       padding: 15px 10px;
                    }
                    /* .sectionBhead{
                       padding-top: 20px;
                    } */
                   
                    .sectionBPara{
                       padding-left: 10px;
                       padding-right: 10px;
                    }
                    .getStart{
                       margin-top: 30px;
                    }
                    .section2{
                       padding-bottom: 30px;
                    }
                   }
                   