.dashboardContainer{
          width: 320px;
          }
          
          
          .userDashboardH1:first-child{
           padding-top: 0;
          }
          .userDashboardH1{
           border-bottom: 4px solid green;
           padding-top: 15px;
           width: 170px;
          }
          .walletDivDiv{
           display: flex;
           justify-content: space-between;
           padding-top: 10px;
          }
          .earnedDiv{
           display: flex;
           justify-content: space-between;
           padding-top: 10px;
          }
          .graduatingDiv{
           display: flex;
           justify-content: space-between;
           /* max-width: 500px; */
           padding-top: 10px;
          
          }
          @media screen and (max-width:768px) {
           .walletDivDiv{
            display: flex;
            padding-top: 10px;
           }
           .earnedDiv{
            display: flex;
            padding-top: 10px;
           }
           .graduatingDiv{
            display: flex;
          
           
           }
           
          }