.stage{
          padding-top: 30px;
          padding-bottom: 80px;
         }
         .stageContainer{
          padding-bottom: 80px;
         
         }
         .stageTable{
                    margin-bottom: 40px;
         }
         .listStat{
                    padding-left: 0;
         }
         .stageHead{
          text-align: center;
          padding-bottom: 25px;
          font-size: 32px;
         }
         .stageHeadSpan{
          color: green;
         }
         .stageFlex{
          display: flex;
          max-width: 700px;
          margin-left: auto;
          margin-right: auto;
          padding-top: 30px;
         }
         .activitiesDiv{
         width: 320px;
         padding: 10px 5px;
         }
         .activitiesHead{
          padding-bottom: 10px;
          text-align: center;
          color: green;
         }
         .activitiesPara{
          font-size: 20px;
         }
         .benefitDiv{
          padding-bottom: px;
          width: 350px;
          background-color: green;
          padding: 15px 10px 15px 5px;
         }
         .benefitHead{
          text-align: center;
          color: #fff;
          padding-bottom: 40px;
         }
         
         .stageList{
          line-height: 25px;
          padding-top: 7px;
          font-size: 16px;
         }
         .stageLists, .stagelistq{
                    font-weight: bolder;
                    line-height: 25px;
          padding-top: 7px;
          font-size: 16px;
          width: 320px;
         }
         .stageLists{
                    text-align: center;
         }
         .stageBtn{
          max-width: 650px;
          margin-left: auto;
          margin-right: auto;
          display: flex;
          justify-content: space-between;
          
         }
         
         .registerMe{
          width: 300px;
          height: 70px;
          background-color: green;
          color: gold;
          font-size: 32px;
          text-align: center;
          font-weight: bold;
          border-radius: 15px;
          padding-top: 5px;
          border: none;
          cursor: pointer;
         }
         
         /* .registerMe:hover{
          max-width: 350px;
         } */


         
         /* Mobile screen */
         @media screen and (max-width: 768px) {
          .stageContainer{
           padding-bottom: 30px;
           padding-left: 10px;
           padding-right: 10px;
          }
          .stageHead{
           padding-bottom: 15px;
           font-size: 24px;
          }
          .stageFlex{
           flex-direction: column;
           padding-top: 10px;
           /* margin-left: 0px;
           margin-right: 0px; */
           /* width: 280px; */
           justify-content: center;
          }
          .activitiesDiv{
           width: 90%;
           }
          .activitiesPara{
           font-size: 16px;
          }
          .stageList{
           line-height: 20px;
           padding-top: 7px;
           font-size: 14px;
          }
          .stageLists{
                    display: none;
          }
          .stageTable{
                    padding: 0 10px;
                    margin-bottom: 20px;
          }
          .benefitDiv{
           width: 90%;
          }
          .stageBtn{
         flex-direction: column;
         gap: 20px;
         padding-left: 10px;
         padding-right: 10px;
           
          }
          .registerMe{
           width: 95%;
           padding-left: 10px;
           padding-right: 10px;
           height: 80px;
          }
         }