
.sidebar{
          font-size: 10px;
         }
         .userContainer{
          width: 250px;
          height: 400px;
          border: 4px solid green;
          
         }
         .listContainer{
          display: block;
         }
         .sidebar{
          font-size: 18px;
          text-decoration: none;
          cursor: pointer;
          padding-left: 15px;
         }
         .userList{
          list-style-type: none;
          padding-bottom: 15px;
          padding-top: 10px;
          width: 100%;
         }
         .sidebar{
                    color: #000;
         }
         .sidebar:hover{
                    color: green;
                    font-weight: bolder;
                    font-size: larger;
         }
         .sidebar:active{
                    color: green;
         }
         hr:last-child{
          display: none;
         }
         @media screen and (max-width:768px){
                    .sidebar:hover{
                                        color: black;
                                        font-weight: normal;
                                        font-size: 16px;
                             }

         }