/* .loginBtnDiv{
 margin-left: auto;
 margin-right: auto;
 max-width: 1200px;
} */
.carousel, .reviewcarousel{
          width: 90vw;
          margin: 3.5rem auto ;
         }
         .carouseldiv1{
          background-image: url('../../asset/images/carou1.jpeg');
          /* background-size: contain; */
         }
         .carouseldiv2{
          background-image: url('../../asset/images/carou2.jpeg');
          /* background-size: contain; */
         
         
         }
         .carouseldiv3{
          background-image: url('../../asset/images/carou3.jpeg');
          /* background-size: contain; */
         
         
         }
         .carousel-status{
          display: none;
         }
         
         .carouseldiv1, .carouseldiv2, .carouseldiv3{
          background-repeat: no-repeat;
          background-size: cover;
          padding: 5rem;
          display: flex;
          justify-content: space-evenly;
          flex-direction: column;
          align-items: center;
          /* width: 700px;
          height: 350px; */
         
         }
         
         
         .carouseltext{
          font-weight: bolder;
          font-size: 3rem;
          line-height: 2.5rem;
          text-align: center;
          color: green;
          margin: 10rem 0 9rem;
          padding-top: 60px;
          text-transform: uppercase;
         
         }