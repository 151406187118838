*{
                    padding: 0;
                    margin: 0;
                }
                .navbar{
                 display: flex;
                 height: 70px;
                 background-color: green;
                 justify-content: space-between;
                 /* gap: 50px; */
                 color: #fff;
                 padding-left: 100px;
                 padding-right: 100px;
                 align-items: center;
                 /* position: fixed; */
                 /* position: sticky; */
                 /* width: 90%; */
                }
                .navbarLogo{
                    height: 70px;
                    width: 70px;
                }
                .menuList{
                 display: flex;
                 align-items: center;
                }
                
                li{
                 list-style-type: none;
                 padding-left: 2rem;
                }
                
                .navbarList{
                 /* text-decoration: none; */
                 color: #fff;
                 font-size: 1.1rem;
                 /* transition: 1s; */
                 text-align: center;
                }
                .navbarList:hover{
                 /* font-size: 1.8rem; */
                 /* padding-left: 35px; */
                 font-weight: bold;
                }
                /* Mobile responsiveness reversal */
                #hamburger{
                 display: none;
                }
                
                .active{
                 flex: 40%;
                }
                
                @media screen and (max-width: 965px) {
                 .navlogo img{
                     width: 7rem;
                     height: 3rem;
                     padding-top: 0.5rem;
                 }
                 .blanck{
                    display: none;
                 }
                 .menuList a{
                     padding: 0 0.5rem;
                 }
                
                 .navlogo{
                     flex: none;
                     padding-right: 5rem;
                 }
                }
                
                @media screen and (max-width: 865px) {
                
                 /* .navlogo{
                     padding-right: 2rem;
                 } */
                
                }
                
                @media screen and (max-width: 768px) {
                
                 #hamburger{
                     display: block;
                     cursor: pointer;
                     font-size: 3rem;
                     color: #fff;
                     position: absolute;
                     right: 40px;
                 }
                 .navbar{
                    padding-left: 30px;
                    padding-right: 30px;
                
                 }
                 
                 .active{
                     position: absolute;
                     top: 4.4rem;
                     left: 0;
                     background-color: rgb(51, 51, 51);
                     width: 100%;
                     display: block;
                     z-index: 1;
                     
                 }
                 
                 .menuList{
                     display: none;
                 }
                
                 .active ul{
                     display: block;
                     height: 70vh;
                     text-align: center;
                     transition: 0.5s;
                     padding: 0 1rem ;
                     
                 }
                
                 .navdiv{
                     display: block;
                 } 
                
                 .navlinks{
                     display: flex;
                     justify-content: space-around;
                     align-items: center;
                     flex-wrap: wrap;
                     
                 }
                 
                 .buttondiv{
                     justify-content: space-around;
                     flex-wrap: wrap;
                     margin-top: 1rem;
                
                 }
                
                 .active li{
                     padding: 1rem  ;
                     margin: 1rem 0;
                     border-radius: 10px;
                     border: 2px solid white;
                     list-style-type: none;
                 }
                 
                 .active a{
                     color: #ffffff;
                     text-decoration: none;
                     font-size: 18px;
                     font-weight: 400;
                     line-height: 22px;
                     text-align: center;
                     /* transition: 1s; */
                     /* padding: 2rem 1.5rem; */
                     
                 }
                
                 #special{
                     /* padding: 1rem 4.2rem; */
                 }
                
                 .active li:hover{
                     background-color: #ffffff;
                     border: 2px solid #FCBC0E;
                 }
                
                 .active a:hover{
                     color: black;
                
                 }
                
                 .navbutton1, .navbutton2{
                     border: 2px solid white;
                     border-radius: 10px;
                     color: #ffffff;
                 }
                 
                }
                
                @media screen and (max-width: 620px) {
                
                 .active ul{
                     height: 92vh;
                 }
                
                }
                
                @media screen and (max-width: 425px) {
                
                 /* .navbar{
                     padding: 0.5rem 1.5rem;
                 } */
                
                 #hamburger{
                     font-size: 3rem;
                 }
                
                }
                
                @media screen and (max-width: 350px) {
                
                 .active a{
                     /* padding: 1rem 5rem; */
                 }
                
                
                 #special{
                     /* padding: 1rem 2.5rem; */
                 }
                
                }
                