.bankdetailshead{
          margin-left: 70px;
          border-bottom: 2px solid red;
          max-width: 400px;
         }
         .note{
          color: red;
          font-size: 12px;
          padding-top: 20px;
          padding-left: 70px;
         }
         .bankDetailsForm{
          padding-left: 70px;
          padding-top: 20px;
         }
         .bankSelect{
          display: block;
          gap: 20px;
         }
         .BankDetailsForm{
          padding-top: 30px;
         }
         .selectContainer{
          max-width: 150px;
          height: 20px;
         }
         .acctType{
          padding-top: 10px;
         }
         .acN{
          padding-top: 20px;
         }
         /* .acN::placeholder{
          padding-left: 10px;
         } */
         ::placeholder{
          padding-left: 10px;
          color: #000;
         }
         .accN{
          padding-top: 10px;
         
         }
         .inputFiller{
          max-width: 220px;
          height: 30px;
          border-radius: 10px;
          border-color: black;
          outline: none;
         }
         .bankdetailsButton{
          padding-top: 20px;
         }
         
         @media screen and (max-width:768px){
          .bankdetailshead{
           margin-left: 0px;
         
          }
          .note{
           padding-left: 0px;
          }
          .bankDetailsForm{
           padding-left: 0px;
          }
          
         }