.updContainer{
          max-width: 350px;
          margin-left: auto;
          margin-right: auto;
          align-items: center;
         }
         .updHead{
          font-size: 36px;
          padding-bottom: 20px;
         }
         
         .UpdLink{
          color: green;
          font-size: 28px;
         }