.home{
 display: flex;

 .adminContainer {
  // background-color: lightblue;
  width: 100%;
  flex: 6;

  .widgetContainer, .chartsContainer{
   display: flex;
   padding: 20px;
   gap: 20px;
  }

  .chartsContainer{
   padding: 5px 20px;
  }

  .listContainer{
   box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
   padding: 20px;
   margin: 20px;

   .listTitle{
    font-weight: 500;
    color: gray;
    margin-bottom: 15px;
   }
  }
 }
}