.userBlock{
          margin:auto;
          max-width: 500px;
          text-align: center;
        //   height: 500px;
          padding-top: 50px;
          padding-bottom: 50px;

          .userInputBlock{
                    padding-top: 30px;
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 30px;

                    .userInput{
                              height: 40px;
                              width: 300px;
                              margin-left: auto;
                              margin-right: auto;
                              margin-bottom: 15px;
                              padding-left: 10px;
                              padding-right: 10px;
                              border-radius: 4px;
                              outline: none;
                    }
                    .userBtn{
                            width: 150px;
                            height: 40px;
                            background-color: green;
                            font-size: 1.2rem;
                            border-radius: 8px;
                            text-align: center;
                            border: none;
                            cursor: pointer;
                            margin-left: auto;
                            margin-right: auto;  
                            outline: none;
                            color: white;
                    }
          }

          .informations{
                text-align: left;
                max-width: 400px;
                margin-left: auto;
                margin-right: auto;

                .userInformation{
                        color: green;
                        text-align: center;
                        padding-bottom: 10px;
                }
                .endpoint{
                        padding-top: 7px;

                        .endpoint:first-child{
                                padding-top: 0;
                        }
                }
          }
}