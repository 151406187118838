
.subLayoutP{
                    position: absolute;
                    right: 90px;
                    color: green;
                    font-size: 24px;
                    padding-top: 20px;
                    font-weight: bold;
                   
                   }
                   .flexDash{
                    display: flex;
                    gap: 100px;
                    /* margin-bottom: 50px; */
                    padding-top: 70px;
                    padding-bottom: 70px;
                   }
                   .usersidediv{
                    padding-left: 80px;
                    /* paddin */
                   }
                   .spa{
                   max-width: 400px;
                   }
                   
                   @media screen  and (max-width:768px) {
                    .subLayoutP{
                     position: absolute;
                     right: 10px;
                     font-size: 18px;
                    }
                    .flexDash{
                     flex-direction: column;
                     gap: 20px;
                    }
                    .usersidediv{
                     padding-left: 10px;
                    }
                    .spa{
                     max-width: 600px;
                     padding-left: 20px;
                     padding-right: 20px;
                    }
                   }