.footerContainer{
                    /* background-color: rgb(51, 51, 51); */
                    background-color: green;
                    /* height: 230px; */
                    font-weight: bolder;
                    padding-top: 16px;
                    padding-bottom: 20px;
                   }
                   .footerBox{
                    display: flex;
                    justify-content: space-between;
                    color: white;
                    flex-wrap: wrap;
                    padding: 0 100px;
                   
                   }
                   .footerLogo{
                    width: 70px;
                    height: 70px;
                   }
                   
                   .div2Link{
                    color: #fff;
                    font-size: 16px;
                    text-decoration: none;
                    margin-bottom: 15px;
                   }
                   .handle{
                    padding-bottom: 10px;
                    font-size: 16px;
                    display: none;
                   }
                   .faIcons{
                    font-size: 70px;
                    color: black;
                   }
                   .copyright{
                    width: 150px;
                    margin-left: auto;
                    margin-right: auto;
                    padding-top: 20px;
                    color: #fff;
                   }
                   
                   @media screen and (max-width: 560px) {
                    .footerContainer{
                     /* height: 450px; */
                     padding-left: 5px;
                     padding-right: 5px;
                    }
                    .footerBox{
                      padding: 0 20px;
                      flex-direction: column;
                      gap: 20px;
                      justify-content: flex-start;
                    }
                    .footerli{
                      padding: 0;
                    }
                    /* .handle{
                     padding-top: 20px;
                    } */
                    .div3{
                       padding-top: 20px;
                    }
                   }