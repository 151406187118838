.bttt{
                    max-width: 700px;
                    margin-left: auto;
                    margin-right: auto;
                    padding-bottom: 100px;
                    margin-top: 60px;
                   }
                   .loginTo{
                    padding-bottom: 50px;
                    text-align: center;
                    font-size: 32px;
                    font-weight: bolder;
                   }
                   
                   .welcomeb{
                    max-width: 300px;
                    height: 50px;
                   background-color: green;
                   padding: 20px 10px 0 20px;
                   font-weight: bolder;
                   cursor: pointer;
                   border-radius: 15px;
                   text-align: center;
                   }
                   
                   .bttFlex{
                    display: flex;
                    max-width: 650px;
                    justify-content: space-around;
                   }
                   .welcomebp{
                    max-width: 300px;
                    height: 50px;
                   background-color: green;
                   padding: 20px 10px 0 20px;
                   font-weight: bolder;
                   cursor: pointer;
                   border-radius: 15px;
                   text-align: center;
                   }
                   
                   .buttwords{
                       /* color: rgba(255, 0, 0, 0.768); */
                       font-size: 14px;
                       /* padding-top: 30px; */
                   }
          
                   /* Mobile screen responsiveness */
                   
                   @media screen and (max-width:768px) {
                    .bttFlex{
                     flex-direction: column;
                     gap: 30px;
                    }
                    
                   }