.aboutContainer{
                    max-width: 800px;
                    margin-left:auto;
                    margin-right: auto;
                    padding-bottom: 50px;
                    padding-top: 50px;
                   }
                   
                   .aboutHead{
                    font-size: 32px;
                    max-width: 200px;
                    margin-left: auto;
                    margin-right: auto;
                   }
                   .subHeading{
                    font-size: 24px;
                   }
                   .partner{
                       font-size: 28px;
                       font-weight: bold;
                       color: green;
                       max-width: 320px;
                       margin-left: auto;
                       margin-right: auto;
                       margin-top: 50px;
                   }
                   
                   @media screen and (max-width:768px) {
                    .aboutContainer{
                     padding-left: 12px;
                     padding-right: 12px;
                    }
                    .aboutHead{
                       font-size: 24px;
                      }
                   
                      .aboutContent1{
                       font-size: 14px;
                      }
                      .aboutListing{
                       font-size: 14px;
                      }
                   }