.referralcodeContainer{
          max-width: 300px;
         }
         .referralCodehead{
          border-bottom: 2px solid red;
          max-width: 300px;
         }
         .referralcodeContent{
          padding-top: 20px;
         
         }
         .code{
          color: green;
          padding-top: 30px;
          text-align: center;
         }
         .sharelikediv{
          display: flex;
          align-items: center;
          width: 70%;
          margin-left: auto;
          margin-right: auto;
          padding-top: 10px;
         }
         .buttonDiv{
          display: flex;
         }
         .shareinActive{
          display: none;
         }
         /* .shareimg{
          display: none;
         } */