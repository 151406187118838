
.forgotContainer{
          padding-left: 200px;
          height: 350px;
          margin-top: 50px;
         }
         .forgetHead{
          border-bottom: 2px solid red;
          max-width: 205px;
          padding-bottom: 10px;
         }
         .forgotP{
          padding-top: 15px;
         }
         .forgetInput{
          padding-bottom: 30px;
          padding-top: 10px;
         }
         .forgetButton{
          padding-bottom: 90px;
         }
         .input_type{
                    height: 25px;
                    width: 200px;
                    padding: 0 10px;
                    margin-left: 20px;
         }
         .message{
                    color: red;
                    font-size: 24px;
                    font-weight: 700;
         }
         
         @media screen and (max-width:768px){
          .forgotContainer{
           padding-left: 10px;
           padding-right:10px;
           margin-top: 10px;
         
          }
         }