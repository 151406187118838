
.confirmationContainer{
 max-width: 400px;
 height: 300px;
 margin-top: 90px;
 margin-left: auto;
 margin-right: auto;
 /* border: 1px solid gold; */
 text-align: center;
}
.confirmationh2{
 color: green;
 font-size: 60px;
 padding-bottom: 10px;
}
.resetLink{
 text-decoration: none;
 font-size: 18px;
 padding-top: 10px;
 color: green;
}